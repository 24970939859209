import 'bootstrap' // importeer bootstrap JavaScript code

import 'bootstrap/dist/css/bootstrap.css' // importeer bootstrap CSS code
import './css/style.css'

const navLinks = document.querySelectorAll(".nav-link:not(.fb-link)");
const a = document.querySelectorAll("a:not(.text-link):not(.fb-link)");
let sections = document.querySelectorAll("section");
const img = document.querySelector(".cover-pipa");

let setActive = function () {
    setTimeout(() => {
        let dispSec = getDisplayedSection().id;
        setHomePage();
        window.location.href = "#" + dispSec;
        Array.from(navLinks).forEach(function (navLink) {
            if (navLink.getAttribute("href") === "#" + dispSec) {
                removeActive();
                navLink.classList.add("active");
                setTitle();
            }
        });
    }, 1)
}

let removeActive = function () {
    Array.from(navLinks).forEach(function (element) {
        element.classList.remove("active");
    });
}

let setPage = function () {
    setTimeout(() => {
        setActive();
        if (getDisplayedSection().id === "home") {
                window.location.href = "#home";
                window.scrollTo({top: 0, behavior: 'smooth'});
        }
            hideNavbar();
        }, 1)
    }

let getDisplayedSection = function () {
    let displayedSection = document.getElementById('home');
    Array.from(sections).forEach(function (section) {
        if (window.getComputedStyle(section).display !== "none" && section.id !== 'home-section' && section.id !== 'nieuws') {
            displayedSection = section;
        }
    });
    return displayedSection;
}

let setHomePage = function () {
    if (getDisplayedSection().id === "home" && window.innerWidth < 992) {
        img.style.display = "block";
    } else {
        img.style.display = "none";
    }
}

let hideNavbar = function () {
    document.querySelector("#navbarNavAltMarkup").classList.remove("show");
}

let setTitle = function () {
    let dispSec = getDisplayedSection().id;
    if (dispSec !== "home") {
        document.querySelector('title').textContent = "Soft Velvet: " + dispSec.charAt(0).toUpperCase() + dispSec.slice(1);
    } else {
        document.querySelector('title').textContent = "Soft Velvet Australian Cobberdogs België";
    }
}

window.onload = setPage;
window.addEventListener("resize", setHomePage);
Array.from(a).forEach(function (element) {
    element.addEventListener('click', setPage);
});

Array.from(sections).forEach(function (element) {
    element.addEventListener('click', hideNavbar);
});





